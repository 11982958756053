<template>
  <!-- <img src="./assets/logo.png">
  <div>
    <p>
      If Element Plus is successfully added to this project, you'll see an
      <code v-text="'<el-button>'"></code>
      below
    </p>
    <el-button type="primary">el-button</el-button>
  </div>
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="common-layout">
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <el-main>
        <Main />
      </el-main>
      
      <div class="line"></div>
      <el-footer>
        Copyright © 2021 EcoGreen. All rights reserved.
      </el-footer>
    </el-container>
  </div>

</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Header from './components/Header.vue'
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Header,
    Main,
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c5042;
}
  .el-header,
  .el-footer {
    /* background-color: #d0f0e9; */
    /* color: var(--el-text-color-primary); */
    text-align: center;
    line-height: 60px;
  }
  .el-main {
    /* background-color: #e9eef3; */
    /* color: var(--el-text-color-primary); */
    text-align: center;
    line-height: 160px;
  }

</style>
