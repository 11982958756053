<template>
  <el-row :gutter="10">
	<el-col :span="16">
		<div>
			
		</div>
	</el-col>
	<el-col :span="8">
	<div class="grid-content bg-purple">
		<el-menu
		:default-active="activeIndex"
		class="el-menu-demo"
		mode="horizontal"
		@select="handleSelect"
		>
		<el-menu-item index="1">Algorithms</el-menu-item>
		<el-menu-item index="2">Mathematic</el-menu-item>
		<!-- <el-sub-menu index="2">
			<template #title>我的工作台</template> -->
			<!-- <el-menu-item index="2-1">选项1</el-menu-item>
			<el-menu-item index="2-2">选项2</el-menu-item>
			<el-menu-item index="2-3">选项3</el-menu-item> -->
			<!-- <el-sub-menu index="2-4">
			<template #title>选项4</template>
			<el-menu-item index="2-4-1">选项1</el-menu-item>
			<el-menu-item index="2-4-2">选项2</el-menu-item>
			<el-menu-item index="2-4-3">选项3</el-menu-item>
			</el-sub-menu> -->
		<!-- </el-sub-menu>	 -->
		<el-menu-item index="3">JVM</el-menu-item>
		<el-menu-item index="4">Rubic Cube</el-menu-item>
		</el-menu>
		<div class="line"></div>
	</div>
	</el-col>
</el-row>
</template>


<script>
  export default {
	name: 'Header',
    data() {
      return {
        activeIndex: '1',
      }
    },
    methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath)
      },
    },
  }
</script>

<style>

</style>
